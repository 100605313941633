<template>
  <CommonBaseBModal
    :id="modalId"
    modal-class="modal-search"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    @show="onShowModal"
    @hide="onHideModal"
  >
    <div class="modal-search__header">
      <span class="modal-search__header--title">Tìm kiếm</span>
      <BaseImg src="/assets/images/icon/icon-close-popup.svg" class="modal-search__header--close-btn" @click="closeModalSearch" />
    </div>
    <form @submit.prevent="">
      <CommonBaseInput
        v-model="search"
        name="search"
        class="input-search"
        autocomplete="off"
        aria-autocomplete="none"
        placeholder="Nhập tên Game"
        :close="false"
        :limit-input="VALIDATION_INPUT.MAX_LENGTH_SEARCH_GAME"
        @update:model-value="onValueUpdate"
      >
        <span v-if="search" class="btn-clear" @click="clearSearch">Xóa</span>
      </CommonBaseInput>
    </form>
    <ResultSearch
      v-if="isSearched"
      :is-loading="isLoading"
      :total-game="totalGame"
      :list-data="resultGames"
      :page="page"
      :keyword-search="textSearch"
      :suggested-keywords="suggestedKeywords"
      :on-press-keyword="selectedKeyword"
      @load-more="searchGame($event)"
    />
    <div v-else>
      <div v-show="search.trim().length === 1" class="require-min-length">
        {{ `Nhập từ ${MIN_SEARCH} ký tự để bắt đầu tìm kiếm.` }}
      </div>
      <RecentlySearch v-show="recentlySearch.length" :list-data="recentlySearch" @selected-keyword="selectedKeyword" />
      <RecentlyGames v-show="recentlyGames.length" :list-data="recentlyGames" />
      <SuggestedKeywords
        :class="{ show: !isLoading && !resultGames.length }"
        :list-data="suggestedKeywords"
        :on-press="selectedKeyword"
      />
    </div>
  </CommonBaseBModal>
</template>
<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import SuggestedKeywords from './suggested-keyword.vue'
import ResultSearch from './result.vue'
import RecentlySearch from './recently-search.vue'
import RecentlyGames from './recently-game.vue'
import CommonBaseInput from '~/components/common/base-input.vue'
import CommonBaseBModal from '~/components/common/base/BModal.vue'
import useModal from '~/composables/useModal'
import { useSearch } from '~/composables/useSearch'
import { useAppStore } from '~/store/app'
import { MODAL_TYPES } from '~/config/constant'
import { VALIDATION_INPUT } from '~/constants/common'

const { $pinia } = useNuxtApp()

const { closeModal, closeAllModal, openModal } = useModal()
const route = useRoute()
const modalId = ref(MODAL_TYPES.SEARCH)
const isShowModal = ref(false)
const textSearch = ref('')
const MIN_SEARCH = 2
const showRequireMinLength = ref(false)

const {
  isLoading,
  search,
  recentlySearch,
  recentlyGames,
  suggestedKeywords,
  totalGame,
  page,
  resultGames,
  searchGame,
  updateQuery,
  isSearched,
  removeQuery,
  formatQuery
} = useSearch()

const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)

const shouldTriggerSearch = ref(true)

const { $bus } = useNuxtApp()

onMounted(() => {
  if (Object.hasOwn(route.query, 'search')) {
    const searchQuery = route.query.search
    if (typeof searchQuery === 'string') {
      search.value = searchQuery.replace(/-/g, ' ')
    }
    nextTick(() => {
      setTimeout(() => {
        openModal(MODAL_TYPES.SEARCH)
      }, 500)
    })
  }

  $bus.$on('bv::show::modal', ({ modalId }: any) => {
    if (modalId === MODAL_TYPES.SEARCH) {
      onShowModal()
    }
  })
  $bus.$on('bv::hide::modal', (payload: { modalId: string }) => {
    if (payload.modalId === MODAL_TYPES.SEARCH) {
      onHideModal()
    }
  })
})

watch(search, (value) => {
  if (!value && isShowModal.value) {
    updateQuery('')
  }
})

watch(isShowModal, async (value) => {
  if (value) {
    page.value = 1
    await searchGame(null)
  }
})

watch(currentUser, (value) => {
  isShowModal.value = false
})

const onShowModal = () => {
  isShowModal.value = true
  page.value = 1

  if (!currentUser.value) {
    recentlySearch.value = []
    recentlyGames.value = []
  }
}

const onHideModal = () => {
  isShowModal.value = false
  isSearched.value = false
  page.value = 1
  search.value = ''
  recentlySearch.value = []
  resultGames.value = []
}

const closeModalSearch = () => {
  textSearch.value = ''
  closeAllModal()
  closeModal(modalId.value)
  removeQuery()
}

const selectedKeyword = (item) => {
  shouldTriggerSearch.value = false
  search.value = item
  page.value = 1
  handleSearchGame()
}

onUpdated(() => {
  if (isShowModal.value) {
    search.value = ''
    page.value = 1
    isSearched.value = false
    resultGames.value = []
  }
})

const onValueUpdate = debounce((value) => {
  if (shouldTriggerSearch.value) {
    handleSearchGame()
  } else {
    shouldTriggerSearch.value = true
  }
}, 1000)

const clearSearch = () => {
  search.value = ''
  page.value = 1
  isSearched.value = false
  resultGames.value = []
  updateQuery('')
}

const handleSearchGame = () => {
  if (Object.hasOwn(route.query, 'search') && search.value.trim().length === 0) {
    clearSearch()
  }

  if (search.value.trim().length >= MIN_SEARCH) {
    page.value = 1
    textSearch.value = search.value
    resultGames.value = []
    isSearched.value = true
    searchGame(null)
    showRequireMinLength.value = false
  } else {
    showRequireMinLength.value = search.value.length === 1
    isSearched.value = false
  }
}
</script>
<style lang="scss" scoped src="/public/assets/scss/components/desktop/modals/search/index.scss" />
